<template>
    <div class="right__subsection">
      <p class="right__subsection__title">相关产品</p>
      <div class="right__subsection__flex">
        <p class="right__subsection__english">Product Center</p>
        <router-link to="/center" class="right__subsection__more">更多产品详情 >></router-link>
      </div>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
.right__subsection__flex {
  display: flex;
  justify-content: space-between;
  .right__subsection__more {
    font-size: 17px;
    font-family: Microsoft YaHei UI;
    font-weight: 500;
    line-height: 25px;
    color: #009049;
  }
}
.right__subsection {
 margin-top: 70px;
 margin-bottom: 20px;
 .right__subsection__title {
   font-size: 22px;
   font-family: Microsoft YaHei UI;
   font-weight: bold;
   line-height: 30px;
   color: #333333;
 }
 .right__subsection__english {
   font-size: 22px;
   font-family: Microsoft YaHei UI;
   font-weight: bold;
   line-height: 30px;
   color: #eaeaea;
   margin-top: 5px;
 }
}
</style>