<template>
     <div class="right__subsection">
      <p class="right__subsection__title">页面展示</p>
      <p class="right__subsection__english">Page Display</p>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
.right__subsection {
  margin-top: 70px;
  margin-bottom: 20px;
  .right__subsection__title {
    font-size: 22px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
  }
  .right__subsection__english {
    font-size: 22px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #eaeaea;
    margin-top: 5px;
  }
}
</style>