<template>
  <div>
    <p class="right__title">
      解决方案 – 智慧生命体征采集系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      生命体征采集是一种集合大数据、物联网、移动通信等信息技术+医疗设备专业制造技术的新型护理仪器,将采集到的数据实时回传到文书记录系统，为患者全生命周期的数据管理提供有效依据。
    </p>
    <img src="../../../assets/img/schemeList03.jpg" alt="" class="life" />
    <FunctionalTitle></FunctionalTitle>
    <div class="functional-content">
      <p>异常报警即时治疗</p>
      <p>防止人工手写记录、输入的出错情况</p>
      <p>减少护理人员工作量</p>
    </div>
    <div class="functional-content__two-line">
      <p>应用物联网技术，系统安装无需布线</p>
      <p>直接从床旁的智能设备采集患者相关的生命体征数据</p>
    </div>
    <ProductTitle></ProductTitle>
    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">血压仪</p>
            <p class="product__first-line__english">Blood Pressure Meter</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call">
        <div class="product__first-line__call-img intercom-host">
          <div>
            <p class="product__first-line__title">体温枪</p>
            <p class="product__first-line__english">
              thermogun
            </p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="product__first-line__call-img LCD-img">
          <div>
            <p class="product__first-line__title">指尖血氧仪</p>
            <p class="product__first-line__english">
              Fingertip Oximeter
            </p>
          </div>
        </div>
      </div>
    </div>
    <PageTitle></PageTitle>
    <div class="page-display">
        <img src="../../../assets/img/schemeList03Display01.jpg" alt="">
        <img src="../../../assets/img/schemeList03Display02.jpg" alt="">
        <img src="../../../assets/img/schemeList03Display03.jpg" alt="">
        <div>移动护理APP生命体征数据测量页面</div>
    </div>
  </div>
</template>

<script>
import FunctionalTitle from "../../../components/functionalTitle";
import ProductTitle from "../../../components/productTitle";
import PageTitle from "../../../components/pageTitle"
export default {
  components: {
    FunctionalTitle,
    ProductTitle,
    PageTitle
  },
};
</script>

<style lang="scss" scoped>
.page-display{
    width: 100%;
    height: 690px;
    background-color: #F9F9F9;
    box-sizing: border-box;
    padding: 30px;
    img{
        width: 270px;
        height: 585px;
    }
    img:nth-child(1){
        margin-right: 17px;
    }
    img:nth-child(2){
        margin-right: 17px;
    }
    div{
        flex: 1;
        font-size: 24px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        text-align: center;
        margin-top: 20px;
    }
}
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  .product__first-line__call {
    width: 310px;
    height: 100%;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemeList03Center01.jpg") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      background: url("../../../assets/img/schemeList03Center02.jpg") no-repeat;
      background-size: 100% 100%;
    }
  }
  .LCD {
    width: 270px;
    height: 100%;
    .LCD-img {
      background: url("../../../assets/img/schemeList03Center03.jpg") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.functional-content__two-line {
  width: 100%;
  height: 85px;
  line-height: 85px;
  color: #464545;
  font-size: 16px;
  text-align: center;
  background-color: #fff;
  margin-top: 35px;
  p {
    width: 365px;
    height: 100%;
    background-color: #f9f9f9;
    display: inline-block;
  }
  p:nth-child(1) {
    margin-right: 35px;
  }
  p:nth-child(2) {
    width: 500px;
  }
}
.functional-content {
  width: 100%;
  height: 85px;
  line-height: 85px;
  color: #464545;
  font-size: 16px;
  text-align: center;
  background-color: #fff;
  p {
    width: 255px;
    height: 100%;
    background-color: #f9f9f9;
    display: inline-block;
  }
  p:nth-child(1) {
    margin-right: 35px;
  }
  p:nth-child(2) {
    width: 325px;
    margin-right: 35px;
  }
}
.life {
  width: 100%;
  height: 690px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
