<template>
  <div>
    <p class="right__title">
      智慧护士查房系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      对术后的一级病房巡查，大多数的医院都规定为每15分钟进行巡查一次，观测术后人员的身体状况，通过的系统可细致的查看出，医护人员有无按要求进行巡查。正常的二级病房巡查，大多数的医院每天巡查一次的，一般都是由护士长带领进行定点的查询，这样我们就可以通过的们的软件了解有多少医护人员参加查询，平均每天有多少护理参加，而且省去了每天每人签查房记录的工作步骤，只需填写病人的，生命体态和身体状态，药物的使用状况。<br />
      &emsp;&emsp;夜间查房，医院每天都有大量的医护人员进行每天夜间的查房工作，除重要的病人病情外，每人每晚都要进行一次巡查，通过我们的管理方案和器材，就可以完整的查看，医护人员在什么时间在哪间病房，甚至准确的停留时间。这样就大大的减少了在工作中不应出现的意外，同时医护人员也为病人体现了准确到位的服务。
    </p>
    <img src="../../../assets/img/schemeList08.jpg" alt="" class="ward-round" />
    <ProductTitle></ProductTitle>
    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">巡房刷卡器</p>
            <p class="product__first-line__english">Room Tour Card Reader</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call">
        <div class="product__first-line__call-img intercom-host">
          <div>
            <p class="product__first-line__title">IC卡</p>
            <p class="product__first-line__english">
              IC Card
            </p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="product__first-line__call-img LCD-img">
          <div>
            <p class="product__first-line__title">巡房管理器</p>
            <p class="product__first-line__english">
              The Rounds Manager
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductTitle from "../../../components/productTitle";

export default {
  components: {
    ProductTitle,
  },
};
</script>

<style lang="scss" scoped>
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  .product__first-line__call {
    width: 310px;
    height: 100%;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemeList08Center01.png") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      background: url("../../../assets/img/schemeList08Center02.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .LCD {
    width: 270px;
    height: 100%;
    .LCD-img {
      background: url("../../../assets/img/schemeList08Center03.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.ward-round {
  width: 100%;
  height: 690px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
