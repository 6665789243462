<template>
  <div>
    <p class="right__title"><span>智慧呼叫对讲系统</span></p>
    <p class="right__details">
      智能护理呼叫系统基于liuxs平台研发，具有良好的开放性和可扩展性，旨在为患者提供舒适的住院环境和优质的服务体验，减轻护士的工作负担，提高医院的信息化水平。其核心终端设备主要有：床头分机、手持分机、门口分机、紧急呼叫分机、医护分机、值班分机、走廊显示屏、卫生间分机、淋浴间分机、门灯及信息看板等。
    </p>
    <img src="../../../assets/img/schemeTopology.jpg" alt="" class="topology" />
    <div class="right__subsection">
      <p class="right__subsection__title">功能描述</p>
      <p class="right__subsection__english">Functional description</p>
    </div>
    <div class="right__description">
      <div v-for="item in escription" :key="item.title">
        <p class="description__title">{{ item.title }}</p>
        <p class="description__details">{{ item.content }}</p>
      </div>
    </div>
    <div class="right__subsection">
      <p class="right__subsection__title">相关产品</p>
      <div class="right__subsection__flex">
        <p class="right__subsection__english">Product Center</p>
        <router-link to="/center" class="right__subsection__more">更多产品详情 >></router-link>
      </div>
    </div>
    <div class="right__product">
      <div class="right__product__first-line">
        <div class="product__first-line__call">
          <div class="product__first-line__call-img">
            <div>
              <p class="product__first-line__title">呼叫分机</p>
              <p class="product__first-line__english">Call Extension</p>
            </div>
          </div>
        </div>
        <div class="product__first-line__call">
          <div class="product__first-line__call-img intercom-host">
            <div>
              <p class="product__first-line__title">医护对讲主机</p>
              <p class="product__first-line__english">Medical Intercom Host</p>
            </div>
          </div>
        </div>
        <div class="product__first-line__call LCD">
          <div class="product__first-line__call-img LCD-img">
            <div>
              <p class="product__first-line__title">液晶走廊屏</p>
              <p class="product__first-line__english">LCD Corridor Screen</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right__product__two-line">
        <div class="product__two-line">
          <div class="product__two-line__img">
            <div>
              <p class="product__two-line__title">点阵双面走廊屏</p>
              <p class="product__two-line__english">Dot-Matrix Two-Sided Hallway Screen</p>
            </div>
          </div>
        </div>
        <div class="product__two-line toilet">
          <div class="product__two-line__img toilet-img">
            <div>
              <p class="product__two-line__title">淋浴呼叫分机</p>
              <p class="product__two-line__english">Shower call extension</p>
            </div>
          </div>
        </div>
        <div class="product__two-line door">
          <div class="product__two-line__img door-img">
            <div>
              <p class="product__two-line__title">门灯</p>
              <p class="product__two-line__english">Door lamp</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      escription: [
        { title: "呼叫功能", content: "分为普通呼叫、紧急呼叫、增援呼叫等" },
        {
          title: "对讲功能",
          content:
            "通过医护分机，值班分机界面点击患者床位及其他分机设备，可进行实时对讲",
        },
        {
          title: "信息发布及展示功能",
          content: "可同步HIS医嘱信息，发布到各终端设备上显示",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.scheme-right {
  flex: 1;
  margin-left: 45px;
  .right__title {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #e8e8e8;
    display: block;
    line-height: 45px;
    text-align: center;
    padding-bottom: 5px;
    margin-top: 20px;
    font-size: 26px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    line-height: 38px;
    color: #333333;
    span {
      border-bottom: 2px solid #189142;
      padding-bottom: 14px;
    }
  }
  .right__details {
    margin-top: 30px;
    font-size: 15px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    line-height: 30px;
    color: #666666;
    text-indent: 2em;
  }
  .topology {
    width: 100%;
    height: 688px;
    margin-top: 15px;
  }
  .right__subsection {
    margin-top: 70px;
    margin-bottom: 20px;
    .right__subsection__title {
      font-size: 22px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 30px;
      color: #333333;
    }
    .right__subsection__english {
      font-size: 22px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 30px;
      color: #eaeaea;
      margin-top: 5px;
    }
  }
  .right__description {
    width: 100%;
    height: 173px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 280px;
      height: 100%;
      background-color: #f9f9f9;
      padding: 40px;
      box-sizing: border-box;
      .description__title {
        font-size: 18px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
      }
      .description__details {
        font-size: 15px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 25px;
        margin-top: 8px;
        color: #7b7b7b;
      }
    }
  }
  .right__product {
    width: 100%;
    height: 425px;
    margin-bottom: 50px;
    .right__product__first-line {
      display: flex;
      .product__first-line__call {
        width: 312px;
        height: 206px;
        .product__first-line__call-img {
          width: 100%;
          height: 100%;
          background: url("../../../assets/img/schemeProduct01.png") no-repeat
            center;
          background-size: 100% 100%;
          padding-top: 135px;
          box-sizing: border-box;
          div {
            height: 70px;
            width: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 250%
            );
            padding-top: 15px;
            box-sizing: border-box;
            .product__first-line__title {
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              padding-left: 22px;
            }
            .product__first-line__english {
              color: #fff;
              font-size: 12px;
              font-weight: 400;
              padding-left: 22px;
              margin-top: 3px;
            }
          }
        }
        .intercom-host {
          background: url("../../../assets/img/schemeProduct02.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 10px;
        }
      }
      .LCD {
        width: 270px;
        height: 100%;
        background-color: #fff;
        .LCD-img {
          background: url("../../../assets/img/schemeProduct03.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 10px;
        }
      }
    }
    .right__product__two-line {
      display: flex;
      margin-top: 10px;
      .product__two-line {
        width: 440px;
        height: 206px;
        background-color: #fff;
        .product__two-line__img {
          width: 100%;
          height: 100%;
          background: url("../../../assets/img/schemeProduct04.png") no-repeat
            center;
          background-size: 100% 100%;
          padding-top: 135px;
          box-sizing: border-box;
          div {
            height: 70px;
            width: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 250%
            );
            padding-top: 15px;
            box-sizing: border-box;
            .product__two-line__title {
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              padding-left: 22px;
            }
            .product__two-line__english {
              color: #fff;
              font-size: 12px;
              font-weight: 400;
              padding-left: 22px;
              margin-top: 3px;
            }
          }
        }
      }
      .toilet {
        width: 227.5px;
        height: 100%;
        background-color: #fff;
        .toilet-img {
          background: url("../../../assets/img/schemeProduct05.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 10px;
        }
      }
      .door {
        width: 227.5px;
        height: 100%;
        .door-img {
          background: url("../../../assets/img/schemeProduct06.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 10px;
        }
      }
    }
  }
}
.display {
  width: 100%;
  height: 600px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding: 57px 57px 0 57px;
  img {
    width: 100%;
    height: 450px;
  }
  p {
    font-size: 22px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 90px;
    color: #333333;
    text-align: center;
  }
}
.right__subsection__flex {
  display: flex;
  justify-content: space-between;
  .right__subsection__more {
    font-size: 17px;
    font-family: Microsoft YaHei UI;
    font-weight: 500;
    line-height: 25px;
    color: #009049;
  }
}
</style>
