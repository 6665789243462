<template>
  <div>
    <p class="right__title">
      中央远程生命体征监测系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      在各科室病房及手术室中，体温、心电、呼吸率、血氧饱和度等数据监测均为掌握病情拐点的重要指标。基于先进的物联网和生命体征监测技术，推出全新无接触无线式远程实时生命体征监测系统，医护人员无需每日多次接触测量患者的体温、心电、呼吸率、血氧饱和度数据，且可通过智能移动远程医疗机器人进行无接触式远程问诊，可有效减少医护人员工作量，同时防止交叉感染。
    </p>
    <img src="../../../assets/img/schemeList04.jpg" alt="" class="vital-sign" />
    <FunctionalTitle></FunctionalTitle>
    <div class="functional-content">
      <div>
        <p>实时数据监控</p>
        <p>
          及时发现病人生命体征数据异优势常情况，掌握病情拐点，早发现，早治疗。
        </p>
      </div>
      <div>
        <p>防止交叉感染</p>
        <p>
          一个病人一套设备，体温设备IPX7防水，心电设备IPX5
          防水，均支持酒精消毒。
        </p>
      </div>
      <div>
        <p>降低工作强度</p>
        <p>无接触式测量，节约医护人员每天测量病人生命体征手工录入数据的时间</p>
      </div>
      <div>
        <p>提升管理效率</p>
        <p>
          所有病人信息及数据实时传输至云端，永久保存，智能分析助力大数据管理。
        </p>
      </div>
    </div>
    <ProductTitle></ProductTitle>
    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">体温贴</p>
            <p class="product__first-line__english">Temperature Patch</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call">
        <div class="product__first-line__call-img intercom-host">
          <div>
            <p class="product__first-line__title">心电贴</p>
            <p class="product__first-line__english">
              Heart Tape
            </p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="product__first-line__call-img LCD-img">
          <div>
            <p class="product__first-line__title">血氧监测指环</p>
            <p class="product__first-line__english">
              Blood Oxygen Monitoring Ring
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionalTitle from "../../../components/functionalTitle";
import ProductTitle from "../../../components/productTitle";

export default {
  components: {
    FunctionalTitle,
    ProductTitle,
  },
};
</script>

<style lang="scss" scoped>
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  .product__first-line__call {
    width: 310px;
    height: 100%;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemeList04Center01.jpg") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      background: url("../../../assets/img/schemeList04Center02.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .LCD {
    width: 270px;
    height: 100%;
    .LCD-img {
      background: url("../../../assets/img/schemeList04Center03.jpg") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.functional-content {
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    box-sizing: border-box;
    height: 100%;
    width: 205px;
    background-color: #f9f9f9;
    padding: 30px;
    p:nth-child(1) {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      color: #333333;
    }
    p:nth-child(2) {
      font-size: 14px;
      line-height: 22px;
      margin-top: 10px;
      font-family: Microsoft YaHei UI;
      font-weight: 500;
      color: #707070;
    }
  }
}
.vital-sign {
  width: 100%;
  height: 585px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
