<template>
    <div class="container">
        <div class="product-name">卫生间呼叫分机</div>
        <div class="product-img">
            <img src="../../../assets/img/center09.png" alt="">
        </div>
        <div class="product-details">
            <div class="product-details__top">
                <div>
                    <p class="product-details-nav details" @click="goParameter()"
                        :style="{ 'background': parameterBackground(), 'border-top': parameterBorder() }">
                        <span>技术参数</span>
                    </p>
                    <p class="product-details-nav" @click="goCharacteristic()"
                        :style="{ 'background': characteristicBackground(), 'border-top': characteristicBorder() }">
                        <span class="border-color">功能特点</span>
                    </p>
                </div>
                <p class="consultation">
                    如需咨询，请联系客服
                </p>
            </div>
            <div class="product-details__goParameter" v-if="parameterShow">
                <div class="parameter__left">
                    <p class="translation">Technical parameter</p>
                    <i></i>
                    <span>技术参数</span>
                    <p class="technical">
                        外观尺寸：86*86*60mm
                    </p>
                    <p class="technical">
                        通讯方式：TCP/IP网络通讯
                    </p>
                    <p class="technical">
                        供电方式：5V单独供电/集中供电
                    </p>
                    <p class="technical">
                        最大功率：1W
                    </p>
                </div>
                <img src="../../../assets/img/center009.jpg" alt="">
            </div>
            <div class="product-details__goCharacteristic" v-if="characteristicShow">
                <div class="parameter__left">
                    <p class="translation">Characteristics</p>
                    <i></i>
                    <span>功能特点</span>
                    <p class="technical">
                        100防水
                    </p>
                    <p class="technical">
                        WIFI通讯
                    </p>
                </div>
                <img src="../../../assets/img/center009.jpg" alt="">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            parameterShow: true,
            characteristicShow: false,
        };
    },
    methods: {
        goParameter() {
            this.parameterShow = true;
            this.characteristicShow = false;
        },
        goCharacteristic() {
            this.characteristicShow = true;
            this.parameterShow = false;
        },
        parameterBackground() {
            if (this.parameterShow) {
                return '#fff'
            } else {
                return '#F9F9F9'
            }
        },
        parameterBorder() {
            if (this.parameterShow) {
                return '3px solid #189142'
            } else {
                return '1px solid #F9F9F9'
            }
        },
        characteristicBackground() {
            if (this.characteristicShow) {
                return '#fff'
            } else {
                return '#F9F9F9'
            }
        },
        characteristicBorder() {
            if (this.characteristicShow) {
                return '3px solid #189142'
            } else {
                return '1px solid #F9F9F9'

            }
        }

    }
};
</script>
  
<style lang="scss" scoped>
.container {
    width: 100%;

    .product-name {
        height: 65px;
        background-color: #EFEFEF;
        margin-top: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #333;
    }

    .product-img {
        height: 550px;
        background: #F9F9F9;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 700px;
            height: 440px;
        }
    }

    .product-details {
        margin-top: 35px;
        height: 610px;
        border: 2px solid #ECECEC;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .product-details__top {
            height: 45px;
            background-color: #F9F9F9;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-details-nav {
                cursor: pointer;
                height: 45px;
                display: inline-block;
                padding-top: 13px;
                box-sizing: border-box;

                span {
                    width: 95px;
                    box-sizing: border-box;
                    font-size: 15px;
                    display: inline-block;
                    text-align: center;
                }

                .border-color {
                    border-right: 1px solid #ccc;
                }
            }

            .details {
                box-sizing: border-box;
            }

            .consultation {
                font-size: 15px;
                margin-right: 20px;
            }
        }

        .product-details__goParameter,
        .product-details__goCharacteristic {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 95px 130px;

            .parameter__left {
                width: 260px;

                .translation {
                    font-size: 28px;
                }

                i {
                    width: 22px;
                    height: 2px;
                    display: block;
                    background-color: #189142;
                    margin-top: 10px;
                }

                span {
                    font-size: 20px;
                    margin-top: 10px;
                    display: block;
                    margin-bottom: 30px;
                }

                .technical {
                    font-size: 13px;
                    color: #666666;
                    margin-top: 12px;
                    line-height: 22.5px;

                    strong {
                        font-weight: 700;
                    }
                }
            }

            img {
                width: 335px;
                height: 335px;
            }
        }
    }
}
</style>
  