<template>
  <div>
    <p class="right__title">
      智慧吸氧计量系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      吸氧计时系统该装置以最小系统为处理核心，配合传感器实现病人吸氧的同步计时吧费交互，实时显示吸氧时长、费用。目前医院在实际医疗工作中，对于病人的吸氧计费仍然存在收费不精准的问题，多数是由医护人员定时巡房进行人工计时，缺少精确的设备进行计时收费。且病人在吸氧治疗的过程中并不是持续不断的吸氧，而是间歇吸氧，这就为医院和病人之间对吸氧治疗的标准计时造成了困难，因此收费有一定出入。
    </p>
    <img src="../../../assets/img/schemeList06.jpg" alt="" class="oxygen" />
    <ProductTitle></ProductTitle>
    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">吸氧计时器</p>
            <p class="product__first-line__english">Oxygen Timer</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call intercom">
        <div class=" intercom-host">
        </div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="LCD-img">
        </div>
      </div>
    </div>
    <PageTitle></PageTitle>
    <div class="page-display">
      <img src="../../../assets/img/schemeList06Display.png" alt="" />
      <div>后台管理系统吸氧计时页面</div>
    </div>
  </div>
</template>

<script>
import ProductTitle from "../../../components/productTitle";
import PageTitle from "../../../components/pageTitle";

export default {
  components: {
    ProductTitle,
    PageTitle,
  },
};
</script>

<style lang="scss" scoped>
.page-display {
  width: 100%;
  height: 555px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding: 45px 65px 0 65px;
  img {
    width: 100%;
    height: 435px;
  }
  div {
    flex: 1;
    font-size: 24px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-top: 20px;
  }
}
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .product__first-line__call {
    width: 300px;
    height: 100%;
    background-color: #fff;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemeList06Center01.png") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      background-color: #fff;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      width: 290px;
      height: 100%;
      background-color: #f2f2f2;
      margin-left: 10px;
    }
  }
  .LCD {
    width: 290px;
    height: 100%;
    background-color: #f2f2f2;
    margin-left: 10px;
  }
}
.oxygen {
  width: 100%;
  height: 680px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
