<template>
  <div id="app">
    <div class="callWarpper" >
      <div @click="toTop"><i class="iconfont icon-dingbu"></i></div>
      <div ><i class="iconfont icon-kefu" @click="openCall"></i><CallBox :visible.sync="visible" /></div>
    </div>
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Header from "./components/oweHeader"
import Footer from "./components/oweFooter"
import CallBox from "./components/callBox"
export default {
  components:{
    Header,
    Footer,
    CallBox
  },
  data() {
    return {
      visible:false,
    }
  },
  methods:{
    toTop(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    openCall(){
      this.visible=true
    },
  }
}
</script>
<style lang="scss">
.contentWarpper{
  width: 1200px;
  margin: 0 auto;
}
.callWarpper{
  position: fixed;
  top: 50%;
  right: 10%;
  z-index: 100;
  >div{
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    text-align: center;
    line-height: 60px;
    margin-top: 10px;
    cursor: pointer;
    >.icon-dingbu{
      color: #F48000;
    }
    >.icon-kefu{
      color: #009049;
      position: relative;
    }
    >i{
      font-size: 36px;
      font-weight: 700;
    }
  }
}
</style>
