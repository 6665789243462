import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import './assets/css/font/iconfont.css'

// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//   ak: '4nI8CkvFlRnGAqChgF3tBk0OAjAoX6t4'
// })
// import VueAMap from 'vue-amap';

// Vue.use(VueAMap);

// // 初始化AMap
// VueAMap.initAMapApiLoader({
//   // 高德地图的key
//   key: '7aabecb6fb6f24c69530851398a47692',
//   // 插件集合
//   plugin: ['AMap.Geolocation','AMap.Marker']
// });


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
