import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
import About from "../views/about.vue"
import Affairs from "../views/affairs.vue"
import Center from "../views/center/index"
import Case from "../views/case.vue"
import Product from "../views/solutions/index"
import CallSystem from "../views/solutions/component/callSystem.vue"
import InterSystem from "../views/solutions/component/interSystem.vue"
import OxygenSystem from "../views/solutions/component/oxygenSystem.vue"
import BedsideSystem from "../views/solutions/component/bedsideSystem.vue"
import TransfuseSystem from "../views/solutions/component/transfuseSystem.vue"
import NurseSystem from "../views/solutions/component/nurseSystem.vue"
import WisdomLifeSystem from "../views/solutions/component/wisdomLifeSystem"
import VitalSignSystem from "../views/solutions/component/vitalSignSystem"
import MedicalAlarmSystem from "../views/solutions/component/medicalAlarmSystem"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'app',
    redirect: "/home",
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path:'/about',
    name:'About',
    component: About
  },
  {
    path:'/product',
    name:'Product',
    component: Product,
    redirect: "/product/callSystem",
    children:[
      {
        path:'/product/callSystem',
        name:'callSystem',
        component: CallSystem,
      },
      {
        path:'/product/interSystem',
        name:'interSystem',
        component: InterSystem,
      },
      {
        path:'/product/wisdomLifeSystem',
        name:'wisdomLifeSystem',
        component: WisdomLifeSystem,
      },
      {
        path:'/product/VitalSignSystem',
        name:'VitalSignSystem',
        component: VitalSignSystem,
      },
      {
        path:'/product/MedicalAlarmSystem',
        name:'MedicalAlarmSystem',
        component: MedicalAlarmSystem,
      },
      {
        path:'/product/oxygenSystem',
        name:'oxygenSystem',
        component: OxygenSystem,
      },
      {
        path:'/product/bedsideSystem',
        name:'bedsideSystem',
        component: BedsideSystem,
      },
      {
        path:'/product/transfuseSystem',
        name:'transfuseSystem',
        component: TransfuseSystem,
      },
      {
        path:'/product/nurseSystem',
        name:'nurseSystem',
        component: NurseSystem,
      },
    ]
  },
  {
    path:'/center',
    name:'Center',
    component: Center,
  },
  {
    path:'/affairs',
    name:'Affairs',
    component: Affairs
  },
  {
    path:'/case',
    name:'Case',
    component: Case
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
