<template>
  <div>
    <p class="right__title">
      智慧床旁交互系统
    </p>
    <i class="title-buttom"></i>
    <img src="../../../assets/img/schemeList09.png" alt="" class="bedside" />
    <div class="bedside-content">
      <div class="patient-information">
        <img src="../../../assets/img/schemeList09-1.png" alt="" />
        <p>病患信息</p>
        <p>
          展示病人的基本身份信息、住院信息、护理标签等内容。入院诊断可视具体情况进行展示或隐藏，点击隐藏或展示。患者可通过此功能呼叫护士站，护士在照护端看到呼叫信息后能及时响应患者需求。
        </p>
      </div>
      <div class="hospital-promotion">
        <img src="../../../assets/img/schemeList09-2.png" alt="" />
        <p>医院宣导</p>
        <p>
          以视频的形式或者图文的形式辅助医护人员对患者进行相关疾病的健康宣教。
        </p>
      </div>
      <div class="bill-of-expenses">
        <img src="../../../assets/img/schemeList09-3.png" alt="" />
        <p>费用清单</p>
        <p>
          展示患者住院期间的费用清单，患者随时可进行查看。支持医嘱查询、检查检验报告查询、体温记录、护理记录等。
        </p>
      </div>
      <div class="information">
        <img src="../../../assets/img/schemeList09-4.png" alt="" />
        <p>病患信息</p>
        <p>
          患者入院须知、术前须知等在线审阅并签字确认。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bedside-content {
  width: 100%;
  height: 370px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  div {
    height: 100%;
    width: 210px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    padding: 15px;
    img {
      width: 100%;
      height: 107px;
    }
    p:nth-child(2) {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 25px;
      color: #333333;
      margin-top: 12px;
    }
    p:nth-child(3) {
      font-size: 14px;
      font-family: Microsoft YaHei UI;
      line-height: 24px;
      color: #333333;
      margin-top: 5px;
    }
  }
}
.bedside {
  width: 100%;
  height: 495px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
</style>
