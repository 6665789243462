<template>
  <div class="homeWarpper">
    <section class="section-one" :style="{background: 'url('+ require('../assets/img/navImg.jpg')+')','background-size':'100% 100%',}">
      <i class="iconfont icon-xiangxiazhanhang show-more" @click="toNext" id="next"></i>
      <div class="nav-display">
        <img
          src="../../src/assets/img/navTitle.png"
          alt="智慧病房"
          class="nav-intelligent__ward"
        />
        <div class="nav-intelligent__ward-english">
          <span>I</span>
          <span>N</span>
          <span>T</span>
          <span>E</span>
          <span>L</span>
          <span>L</span>
          <span>I</span>
          <span>G</span>
          <span>E</span>
          <span>N</span>
          <span>T</span>
          <div class="nav__ward-english">
            <span>W</span>
            <span>A</span>
            <span>R</span>
            <span>D</span>
          </div>
        </div>
        <div class="nav__wisdom">助力医疗智慧化、现代化</div>
        <div class="nav__wisdom-english">
          <div>Intelligent ward</div>
          <div>Help the wisdom of medical treatment</div>
        </div>
        <div class="nav__learn-more">
          <router-link to="/product" class="nav__learn-more__text"
            >了解更多 >></router-link
          >
        </div>
      </div>
      <div class="nav-buttom">
        <div class="nav-buttom-middle">
          <div
            class="nav-buttom-list"
            v-for="item in navButtom"
            :key="item.bigText"
          >
            <div class="nav-buttom__white">
              <img :src="item.img" alt="" />
            </div>
            <div class="nav-buttom__text">
              <span class="nav-buttom__big-text">{{ item.bigText }}</span>
              <span class="nav-buttom__small-text">{{ item.small }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us">
      <div class="about-us__left">
        <p class="about-us__haoseen">关于昊森</p>
        <p class="about-us__english">ABOUT US</p>
        <p class="about-us__description">
          &emsp;&emsp;&emsp;成都昊森电子科技有限公司(以下简称昊森公司，是专门从事智慧病房系统研发、生产、营销的公司)，注册成立于成都金牛高科技产业园振兴西一路19号迎宾国际A-4-2号，在成都金牛区设有营销中心，在成都市新都区宝光大道南段704号设立研发中心和生产基地。专注于智慧病房系统的研发，生产和服务。
        </p>
        <router-link to="/about" class="about-us__know-details"
          >了解详情</router-link
        >
      </div>
      <div class="about-us__right">
        <div
          class="about-us__right-flex"
          v-for="item in aboutRight"
          :key="item.english"
        >
          <div class="about-us__right-flex__img">
            <img :src="item.img" alt="" class="about-us__right-flex__img01" />
          </div>
          <p class="about-us__right-flex__text">{{ item.title }}</p>
          <p class="about-us__right-flex__english">{{ item.english }}</p>
        </div>
      </div>
    </section>
    <section class="product-section">
      <div class="product-section-container">
        <div class="product-section-container__title">解决方案及产品</div>
        <i class="iconfont icon-yuanquan"></i>
        <span class="product-section-container__english"
          >PRODUCT SOLUTIONS</span
        >
        <div class="product-section-details">
          <div
            class="product-section-list"
            v-for="item in product"
            :key="item.content"
          >
            <img :src="item.img" alt="" />
            <p class="product-list__title">{{ item.title }}</p>
            <p class="product-list__content">{{ item.content }}</p>
            <div class="product-list__hover"></div>
          </div>
        </div>
        <div class="product-section-Know-details">
          <img src="../../src/assets/img/HOWSEEN.png" alt="" />
          <router-link to="product">了解详情</router-link>
        </div>
      </div>
    </section>
    <section class="case">
      <div class="case__title">合作案例</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="case__english">COOPERATION CASE</span>
      <div class="case-content">
        <div
          class="case-content-list"
          v-for="item in homeCase"
          :key="item.title"
        >
          <img :src="item.img" alt="" />
          <p class="case-content-list__title">{{ item.title }}</p>
          <p class="case-content-list__english">{{ item.english }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navButtom: [
        {
          img: require("../assets/img/homeBanner01.png"),
          bigText: "多种解决方案",
          small: "定制化解决方案",
        },
        {
          img: require("../assets/img/homeBanner02.png"),
          bigText: "产品自主研发",
          small: "拥有自营工厂",
        },
        {
          img: require("../assets/img/homeBanner03.png"),
          bigText: "系统自主研发",
          small: "拥有研发团队，自主研发",
        },
        {
          img: require("../assets/img/homeBanner04.png"),
          bigText: "完善售后服务",
          small: "成熟的售后处理体系",
        },
      ],
      aboutRight: [
        {
          img: require("../assets/img/homeAbout01.png"),
          title: "优质服务",
          english: "Quality service",
        },
        {
          img: require("../assets/img/homeAbout02.png"),
          title: "信息安全",
          english: "Information security",
        },
        {
          img: require("../assets/img/homeAbout03.png"),
          title: "自主研发",
          english: "Independent research",
        },
        {
          img: require("../assets/img/homeAbout04.png"),
          title: "产品保证",
          english: "Product guarantee",
        },
      ],
      product: [
        {
          img: require("../../src/assets/img/homeProduct01.jpg"),
          title: "智慧呼叫对讲系统",
          content:
            "智能护理呼叫系统基于liuxs平台研发，具有良好的开放性和可扩展性，旨在为患者提供舒适的住院环境....",
        },
        {
          img: require("../../src/assets/img/homeProduct02.jpg"),
          title: "电子床头卡、门口机系统",
          content:
            "该系统与医院HIS、CIS、运维系统进行无缝对接，有效的解决了医院病房传统床头卡带来的种种弊端。支....",
        },
        {
          img: require("../../src/assets/img/homeProduct03.jpg"),
          title: "智慧生命体征采集系统",
          content:
            "生命体征采集是一种集合大数据、物联网、移动通信等信息技术+医疗设备专业制造技术的新型护理仪....",
        },
        {
          img: require("../../src/assets/img/homeProduct04.jpg"),
          title: "智慧医气报警系统",
          content:
            "采用无线信号传输，位置布置灵活，不受线路影响，检测气体多样化，解决多种气体检测易混乱的问....",
        },
      ],
      homeCase: [
        {
          img: require("../assets/img/homeCase01.jpg"),
          title: "新都第二人民医院",
          english: "Xindu Second People's Hospital",
        },
        {
          img: require("../assets/img/homeCase02.jpg"),
          title: "四川省人民医院",
          english: "Sichuan Provincial People's Hospital",
        },
        {
          img: require("../assets/img/homeCase03.jpg"),
          title: "新都木兰社区卫生服务中心",
          english: "Xindu Mulan Community Health Service Center",
        },
      ],
    };
  },
  methods: {
    toNext() {
      document.getElementById("next").scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 1200px;
  margin: 0 auto;
  padding-top: 70px;
  .case__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 41px;
    color: #333333;
  }
  i {
    font-size: 12px;
    color: #009049;
    font-weight: 700;
    vertical-align: middle;
    margin-right: 8px;
    display: inline-block;
    margin-top: -10px;
  }
  .case__english {
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #666666;
    display: inline-block;
    margin-top: 15px;
  }
  .case-content {
    width: 100%;
    height: 300px;
    margin-top: 50px;
    margin-bottom: 225px;
    display: flex;
    justify-content: space-between;
    .case-content-list {
      width: 380px;
      height: 300px;
      position: relative;
      font-family: Microsoft YaHei UI;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .case-content-list__title {
        position: absolute;
        font-size: 22px;
        color: #fff;
        top: 73.5%;
        margin-left: 30px;
        font-weight: 700;
      }
      .case-content-list__english {
        position: absolute;
        font-size: 12px;
        color: #fff;
        top: 85%;
        margin-left: 30px;
      }
    }
  }
}
.product-section-list:hover .product-list__title {
  color: #009049 !important;
}
.product-section-list:hover .product-list__hover {
  display: block !important;
}
.product-section {
  width: 100%;
  padding-top: 110px;
  background: #f8f8f8;
  padding-bottom: 75px;
  .product-section-container {
    width: 1200px;
    margin: 0 auto;
    .product-section-container__title {
      font-size: 28px;
      font-weight: bold;
      line-height: 41px;
      color: #333333;
    }
    i {
      font-size: 12px;
      color: #009049;
      font-weight: 700;
      vertical-align: middle;
      margin-right: 8px;
      display: inline-block;
      margin-top: -10px;
    }
    .product-section-container__english {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #666666;
      display: inline-block;
      margin-top: 15px;
    }
    .product-section-details {
      width: 100%;
      height: 400px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      .product-section-list {
        width: 275px;
        height: 100%;
        background-color: #fff;
        img {
          width: 100%;
          height: 185px;
        }
        .product-list__title {
          font-size: 18px;
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          line-height: 25px;
          color: #333;
          margin: 30px 0 0 30px;
        }
        .product-list__content {
          font-size: 12px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          line-height: 25px;
          color: #666666;
          width: 210px;
          height: 80px;
          margin: 25px 0 0 30px;
          border-top: 1px solid #d1d1d1;
          padding-top: 25px;
        }
        .product-list__hover {
          width: 100%;
          height: 5px;
          background-color: #009049;
          margin-top: 22.5px;
          border-radius: 5px;
          display: none;
        }
      }
    }
  }
  .product-section-Know-details {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    img {
      width: 600px;
      height: 130px;
    }
    a {
      margin-top: 20px;
      width: 160px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #009049;
      border-radius: 25px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.about-us {
  height: 490px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 120px;
  display: flex;
  .about-us__left {
    width: 520px;
    height: 490px;
    background: url("../assets/img/homeAboutBackground.jpg") no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    padding: 95px 105px;
    box-sizing: border-box;
    .about-us__haoseen {
      font-size: 34px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #ffffff;
    }
    .about-us__english {
      font-size: 20px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 30px;
      color: #ffffff;
      margin-top: 8px;
    }
    .about-us__description {
      font-size: 12px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
      margin-top: 12px;
    }
    .about-us__know-details {
      width: 140px;
      height: 40px;
      background: #009049;
      border-radius: 25px;
      color: #fff;
      display: flex;
      font-size: 17px;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    }
  }
  .about-us__right {
    width: 680px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 1px solid #ededed;
    border-left: 0px !important;
    .about-us__right-flex {
      width: 339.5px;
      height: 50%;
      box-sizing: border-box;
      border: 1px solid #ededed;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .about-us__right-flex__img {
        width: 75px;
        height: 75px;
        .about-us__right-flex__img01 {
          width: 100%;
          height: 100%;
        }
      }
      .about-us__right-flex__text {
        font-size: 24px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        margin-top: 20px;
        opacity: 1;
      }
      .about-us__right-flex__english {
        font-size: 12px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 15px;
        color: #6b6b6b;
        margin-top: 10px;
      }
    }
  }
}
.nav-display {
  width: 1200px;
  height: 704px;
  margin: 0 auto;
  padding-top: 202px;
  .nav-intelligent__ward {
    width: 305.22px;
    height: 71.91px;
  }
  .nav-intelligent__ward-english {
    margin-top: 20px;
    span {
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 20px;
      color: #3b3b3b;
      margin-right: 10px;
    }
    .nav__ward-english {
      display: inline;
      margin-left: 15px;
    }
  }
  .nav__wisdom {
    width: 437px;
    height: 57px;
    background: #009049;
    font-size: 34px;
    font-family: FZLanTingHei-R-GBK;
    font-weight: 400;
    padding-bottom: 3px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .nav__wisdom-english {
    margin-top: 20px;
    div {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #3b3b3b;
    }
  }
  .nav__learn-more {
    width: 184px;
    height: 58px;
    background: #009049;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
    a {
      font-size: 18px;
      font-family: FZLanTingHei-R-GBK;
      font-weight: 400;
      line-height: 21px;
      color: #ffffff;
    }
    .nav__learn-more__text {
      margin-right: 10px;
    }
  }
}
.nav-buttom-list {
  height: 96px;
  width: 100%;
  border-right: 1px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .nav-buttom__white {
    width: 53px;
    height: 53px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav-buttom__text {
    margin-left: 25px;
    .nav-buttom__big-text {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      line-height: 25px;
      color: #ffffff;
      display: block;
    }
    .nav-buttom__small-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
      display: block;
      margin-top: 6px;
    }
  }
}
.homeWarpper {
  width: 100%;
  .section-one {
    height: 800px;
    // background-image: url("../assets/img/navImg.png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    position: relative;

    .nav-buttom {
      width: 100%;
      height: 96px;
      background: rgba(0, 0, 0, 0.39);
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-buttom-middle {
        width: 1200px;
        height: 100%;
        border-left: 1px solid #ffffff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-evenly;
      }
    }
    i {
      font-size: 60px;
      color: #ffffff;
      position: absolute;
      top: 75%;
      left: 50%;
      cursor: pointer;
      animation: changes 1s 0.2s linear infinite alternate;
    }
  }
}
@keyframes changes {
			0% {
				transform: scale(0.5);
			}
			10% {
				transform: scale(0.6);
			}
      20% {
				transform: scale(0.7);
			}
      30% {
				transform: scale(0.8);
			}
      40% {
				transform: scale(0.9);
			}
      50% {
				transform: scale(1);
			}
      60% {
				transform: scale(1.1);
			}
      70% {
				transform: scale(1.2);
			}
      80% {
				transform: scale(1.3);
			}
      90% {
				transform: scale(1.4);
			}
      100% {
				transform: scale(1.5);
			}
		}
</style>
