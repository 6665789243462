<template>
  <div>
    <p class="right__title">
      医用气体报警系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      采用无线信号传输，位置布置灵活，不受线路影响，检测气体多样化，解决多种气体检测易混乱的问题信息提示多元化，移动端客户端及过道均可显示。预防信息传递不及时。多种类选择，根据客户需要选择适合的产品。
    </p>
    <img src="../../../assets/img/schemeList05.jpg" alt="" class="alarm" />
    <PageTitle></PageTitle>
    <div class="page-display">
        <div class="page-display__first-line">
            <img src="../../../assets/img/schemeList05Display01.jpg" alt="">
            <img src="../../../assets/img/schemeList05Display02.jpg" alt="">
            <img src="../../../assets/img/schemeList05Display03.jpg" alt="">
        </div>
        <div class="page-display__two-line">
            <img src="../../../assets/img/schemeList05Display04.jpg" alt="">
            <img src="../../../assets/img/schemeList05Display05.jpg" alt="">
        </div>
        <p>各设备报警页面</p>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../components/pageTitle";
export default {
  components: {
    PageTitle,
  },
};
</script>

<style lang="scss" scoped>
.page-display{
    width: 100%;
    height: 655px;
    background-color: #F9F9F9;
    padding: 30px;
    box-sizing: border-box;
    .page-display__first-line{
        display: flex;
        justify-content:space-between;
        img{
            width: 265px;
            height: 155px;
        }
    }
    .page-display__two-line{
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        img{
            width: 175px;
            height: 370px;
        }
        img:nth-child(2){
            width: 645px;
        }
    }
    p{
        text-align: center;
        font-weight: bold;
        margin-top: 20px;
        color: #333333;
        font-size: 24px;
        font-family: Microsoft YaHei UI;
    }
}
.alarm {
  width: 100%;
  height: 500px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
