<template>
  <div>
    <p class="right__title">
      电子床头卡、门口机系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      该系统与医院HIS、CIS、运维系统进行无缝对接，有效的解决了医院病房传统床头卡带来的种种弊端。
    </p>
    <img src="../../../assets/img/schemeList01.jpg" alt="" class="bedside" />
    <div class="right__subsection">
      <p class="right__subsection__title">功能描述</p>
      <p class="right__subsection__english">Functional description</p>
    </div>
    <div class="description__first-line">
      <p>可手动控制熄、亮屏</p>
      <p>主治医生介绍内容展示</p>
      <p>展示病房内患者入住情况</p>
    </div>
    <div class="description__two-line">
      <p>支持与病房内床头机交互，实现定格显示</p>
      <p class="description__two-line__two">责任护士介绍内容展示</p>
    </div>
    <div class="right__subsection">
      <p class="right__subsection__title">相关产品</p>
      <div class="right__subsection__flex">
        <p class="right__subsection__english">Product Center</p>
        <router-link to="/center" class="right__subsection__more">更多产品详情 >></router-link>
      </div>
    </div>

    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">病床分机</p>
            <p class="product__first-line__english">Hospital Bed Extension</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call">
        <div class="product__first-line__call-img intercom-host">
          <div>
            <p class="product__first-line__title">病房门口屏</p>
            <p class="product__first-line__english">
              Ward Door Screen
            </p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="product__first-line__call-img LCD-img">
          <div>
            <p class="product__first-line__title">医护对讲主机</p>
            <p class="product__first-line__english">
              Medical Intercom Host
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="right__subsection">
      <p class="right__subsection__title">页面展示</p>
      <p class="right__subsection__english">Page Display</p>
    </div>
    <div class="page-display">
      <img src="../../../assets/img/schemeList02Display01.jpg" alt="" />
      <img src="../../../assets/img/schemeList02Display02.jpg" alt="" />
      <img src="../../../assets/img/schemeList02Display03.jpg" alt="" />
      <div>病房分机页面</div>
    </div>
    <div class="page-display__two-line">
      <img src="../../../assets/img/schemeList02Display04.jpg" alt="" />
      <img src="../../../assets/img/schemeList03Display05.jpg" alt="" />
      <div>病房分机页面</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-display__two-line {
  height: 350px;
  width: 100%;
  background-color: #f9f9f9;
  margin-top: 22px;
  box-sizing: border-box;
  padding: 30px;
  img {
    width: 410px;
    height: 240px;
  }
  img:nth-child(1) {
    margin-right: 25px;
  }
  div {
    flex: 1;
    font-size: 24px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-top: 15px;
  }
}
.page-display {
  width: 100%;
  height: 255px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding: 30px;
  img {
    width: 270px;
    height: 150px;
  }
  img:nth-child(1) {
    margin-right: 17px;
  }
  img:nth-child(2) {
    margin-right: 17px;
  }
  div {
    flex: 1;
    font-size: 24px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-top: 15px;
  }
}
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  .product__first-line__call {
    width: 310px;
    height: 100%;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemeListCenter01.png") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      background: url("../../../assets/img/schemeListCenter02.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .LCD {
    width: 270px;
    height: 100%;
    .LCD-img {
      background: url("../../../assets/img/schemeListCenter03.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.right__subsection__flex {
  display: flex;
  justify-content: space-between;
  .right__subsection__more {
    font-size: 17px;
    font-family: Microsoft YaHei UI;
    font-weight: 500;
    line-height: 25px;
    color: #009049;
  }
}

.description__two-line {
  height: 90px;
  width: 100%;
  line-height: 90px;
  font-size: 16px;
  font-family: Microsoft YaHei UI;
  font-weight: 500;
  color: #626262;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  p:nth-child(1) {
    width: 525px;
    height: 100%;
    text-align: center;
    background-color: #f9f9f9;
  }
  p:nth-child(2) {
    width: 345px;
    height: 100%;
    text-align: center;
    background-color: #f9f9f9;
  }
}
.description__first-line {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    height: 100%;
    width: 280px;
    background-color: #f9f9f9;
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    font-weight: 500;
    color: #626262;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.bedside {
  width: 100%;
  height: 690px;
  margin-top: 20px;
}
.right__subsection {
  margin-top: 70px;
  margin-bottom: 20px;
  .right__subsection__title {
    font-size: 22px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
  }
  .right__subsection__english {
    font-size: 22px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    line-height: 30px;
    color: #eaeaea;
    margin-top: 5px;
  }
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
