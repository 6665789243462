<template>
  <div class="wapper">
    <section class="section">
      <div class="contentWarpper scheme-title">
        <img src="../assets/img/affairsH1.png" alt="" />
      </div>
    </section>
    <div class="affairs contentWarpper">
      <p class="current-position">
        您当前的位置：<router-link to="/home">首页</router-link> > 招商加盟
      </p>
      <div class="section__title">为什么加盟</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">WHY US</span>
      <div class="why-us__content">
        <div>
          <img src="../assets/img/join01.png" alt="" />
          <p>自主研发</p>
          <p>Independent research</p>
        </div>
        <div>
          <img src="../assets/img/join02.png" alt="" />
          <p>产品保证</p>
          <p>Product guarantee</p>
        </div>
        <div>
          <img src="../assets/img/join03.png" alt="" />
          <p>优质服务</p>
          <p>Quality service</p>
        </div>
        <div>
          <img src="../assets/img/join04.png" alt="" />
          <p>信息安全</p>
          <p>Information security</p>
        </div>
      </div>
      <div class="section__title cooperation-process">合作流程</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">COOPERATIVE PROCESS</span>
      <img src="../assets/img/process.png" alt="" class="process" />
      <img src="../assets/img/HOWSEEN.png" alt="" class="howseen" />
      <div class="section__title">联系方式</div>
      <i class="iconfont icon-yuanquan"></i>
      <span class="section__english">CONTACT INFORMATION</span>
      <div class="contact">
        <img src="../assets/img/contact01.jpg" alt="" />
        <img src="../assets/img/contact02.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.affairs {
  margin-top: 30px;
  margin-bottom: 250px;
  .current-position {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    color: #666666;
    a {
      color: #009049;
    }
  }
  .section__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 41px;
    color: #333333;
    margin-top: 65px;
  }
  .cooperation-process {
    margin-top: 120px;
  }
  i {
    font-size: 12px;
    color: #009049;
    font-weight: 700;
    vertical-align: middle;
    margin-right: 8px;
    display: inline-block;
    margin-top: -10px;
  }
  .section__english {
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #666666;
    display: inline-block;
    margin-top: 15px;
  }
  .why-us__content {
    width: 100%;
    height: 300px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    div {
      width: 275px;
      height: 100%;
      background-color: #f0f9f4;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
      }
      p:nth-child(2) {
        margin-top: 30px;
        font-size: 22px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #009049;
      }
      p:nth-child(3) {
        margin-top: 5px;
        font-size: 12px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 18px;
        color: #9fd5b9;
      }
    }
    div:nth-child(2) {
      background-color: #fffaf3;
      p:nth-child(2) {
        color: #f1a33b;
      }
      p:nth-child(3) {
        color: #fadeba;
      }
    }
    div:nth-child(3) {
      background-color: #f5f7fd;
      p:nth-child(2) {
        color: #527cdc;
      }
      p:nth-child(3) {
        color: #bcccf2;
      }
    }
    div:nth-child(4) {
      background-color: #f1f9fa;
      p:nth-child(2) {
        color: #1e95a3;
      }
      p:nth-child(3) {
        color: #b8dee2;
      }
    }
  }
  .process {
    width: 100%;
    height: 265px;
    margin-top: 80px;
  }
  .howseen {
    width: 600px;
    height: 130px;
    margin-top: 50px;
  }
  .contact {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 410px;
    margin-top: 50px;
  }
}
.section {
  background: url("../assets/img/affairsBanner.jpg") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  height: 455px;
  padding-top: 110px;
  box-sizing: border-box;
  .scheme-title {
    img {
      width: 500px;
      height: 250px;
    }
  }
}
</style>
