<template>
  <div class="warpper">
    <section class="section">
      <div class="contentWarpper scheme-title">
        <img src="../../assets/img/schemeH1.png" alt="" />
      </div>
    </section>
    <section class="scheme contentWarpper">
      <p class="scheme-current-position">
        您当前的位置：<router-link to="/home">首页</router-link> > 解决方案
      </p>
      <div class="scheme-content">
        <div class="scheme-left">
          <p class="scheme-left__title">智慧病房解决方案</p>
          <router-link to="/product/callSystem" class="scheme-left__title list-li">智慧呼叫对讲系统</router-link>
          <router-link to="/product/interSystem" class="scheme-left__title list-li">电子床头卡、门口机系统</router-link>
          <router-link to="/product/wisdomLifeSystem" class="scheme-left__title list-li">智慧生命体征采集系统</router-link>
          <router-link to="/product/vitalSignSystem" class="scheme-left__title list-li">中央远程生命体征采集系统</router-link>
          <router-link to="/product/MedicalAlarmSystem" class="scheme-left__title list-li">医用气体报警系统</router-link>
          <router-link to="/product/oxygenSystem" class="scheme-left__title list-li">智慧吸氧计量系统</router-link>
          <router-link to="/product/transfuseSystem" class="scheme-left__title list-li">智慧输液报警系统</router-link>
          <router-link to="/product/nurseSystem" class="scheme-left__title list-li">智慧护士查房系统</router-link>
          <router-link to="/product/bedsideSystem" class="scheme-left__title list-li">智慧床旁交互系统</router-link>
          <p class="scheme-left__title smart-medicine-title">
            智慧药品库房温湿度解决方案
          </p>
          <p class="scheme-left__title list-li">智慧药品库房温湿度解决方案</p>
          <p class="scheme-left__title smart-medicine-title">
            智慧公厕解决方案
          </p>
          <p class="scheme-left__title list-li">智慧公厕解决方案</p>
          <p class="scheme-left__title contact-us">
            联系我们<span>/ Contact Us</span>
          </p>
          <div class="contact-us-content">
            <p>
              <img src="../../assets/img/schemeAboutUs01.png" alt="" />
              <span>服务热线：028-87750979</span>
            </p>
            <p>
              <img src="../../assets/img/schemeAboutUs02.png" alt="" />
              <span>销售电话：028-87750979</span>
            </p>
            <p>
              <img src="../../assets/img/schemeAboutUs03.png" alt="" /><span>电子邮箱：775892738 @qq.com</span>
            </p>
          </div>
        </div>
        <div class="scheme-right">
          <router-view />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.warpper {
  width: 100%;

  .section {
    background: url("../../assets/img/schemeNav.jpg") no-repeat center;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    height: 455px;
    padding-top: 110px;
    box-sizing: border-box;

    .scheme-title {
      img {
        width: 500px;
        height: 250px;
      }
    }
  }

  .scheme {
    margin-top: 30px;
    margin-bottom: 75px;

    .scheme-current-position {
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      color: #666666;

      a {
        color: #009049;
      }
    }

    .scheme-content {
      display: flex;
      justify-content: space-between;

      .scheme-left {
        width: 250px;
        height: 1000px;
        margin-top: 25px;

        .scheme-left__title {
          display: block;
          width: 100%;
          height: 43px;
          background-color: #009049;
          color: #fff;
          font-size: 16px;
          font-family: Microsoft YaHei UI;
          font-weight: bold;
          line-height: 43px;
          padding-left: 20px;
          box-sizing: border-box;
          margin-bottom: 5px;

          &.router-link-exact-active {
            font-weight: bold;
            color: #009049;
            background: #e5f4ec;
          }

          span {
            font-size: 16px;
            font-family: Microsoft YaHei UI;
            font-weight: bold;
            line-height: 23px;
            color: #64bb8f;
            margin-left: 10px;
          }
        }

        .list-li {
          background-color: #f6f6f6;
          color: #333333;
          font-weight: normal;
          font-size: 14px;
        }

        .smart-medicine-title {
          margin-top: 15px;
        }

        .contact-us {
          margin-top: 40px;
        }

        .contact-us-content {
          width: 100%;
          height: 151px;
          border: 1px solid #009049;
          margin-top: -10px;
          box-sizing: border-box;
          padding: 6px;
          padding-top: 5.5px;

          p {
            width: 100%;
            height: 40px;
            background-color: #f6f6f6;
            margin-bottom: 7px;
            margin-top: 3.5px;
            display: flex;
            color: #333333;
            align-items: center;

            img {
              width: 22px;
              height: 22px;
              margin-left: 10px;
            }

            span {
              margin-left: 5px;
            }
          }
        }
      }

      .scheme-right {
        flex: 1;
        margin-left: 45px;
      }
    }
  }
}
</style>
