<template>
  <div>
    <p class="right__title">
      智慧输液报警系统
    </p>
    <i class="title-buttom"></i>
    <p class="right__details">
      在互联网和物联网浪潮的推动下，“智慧医院”概念正在所有医院项目中逐步体现。然而作为医院护理部门日常工作之一的输液，存在着很多弊端：护工看护不能真实客观反映输液者的真实情况、患者及其家属紧张程度高、医疗事故机率大、护理人员对患者的输液过程存在着盲区、护理人员工作压力大、护理人员被动服务。
    </p>
    <img src="../../../assets/img/schemeList07.png" alt="" class="life" />
    <FunctionalTitle></FunctionalTitle>
    <div class="functional-content">
      <div>
        <p class="functional-content__details">
          <strong>智能计算液体滴速， </strong>计算精度达到1ml左右，
          剩余时间精度达到1分钟左右，
          <strong>监控输液过快、 过慢、 滴停、 结束等状态， </strong
          >并进行语音图文、 震动报警。
        </p>
        <p class="functional-content__title">功能描述</p>
        <p class="functional-content__english">Functional Description</p>
      </div>

      <div>
        <p class="mode">
          多种输液模式： 常规输液模式、
          <strong>输血模式、 遮光模式、 </strong>手术室带药模式、 中途换药模式、
          <strong>冲洗模式。 </strong>
        </p>
        <p class="battery">大容量锂电池，可续航工作500小时以上。</p>
      </div>
      <div>
        <p>输液泵 / 注射泵联网报警呼叫系统联网报警。</p>
        <p>系统能自动检测各种输液瓶 / 输液袋规格。</p>
        <p>应用<strong>物联网技术</strong> ，系统安装无需布线。</p>
      </div>
    </div>
    <ProductTitle></ProductTitle>
    <div class="right__product">
      <div class="product__first-line__call">
        <div class="product__first-line__call-img">
          <div>
            <p class="product__first-line__title">智能输液监控器</p>
            <p class="product__first-line__english">Product Center</p>
          </div>
        </div>
      </div>

      <div class="product__first-line__call intercom">
        <div class=" intercom-host"></div>
      </div>

      <div class="product__first-line__call LCD">
        <div class="LCD-img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionalTitle from "../../../components/functionalTitle";
import ProductTitle from "../../../components/productTitle";
export default {
  components: {
    FunctionalTitle,
    ProductTitle,
  },
};
</script>

<style lang="scss" scoped>
.right__product {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .product__first-line__call {
    width: 300px;
    height: 100%;
    background-color: #fff;
    .product__first-line__call-img {
      width: 100%;
      height: 100%;
      background: url("../../../assets/img/schemnList07Center.png") no-repeat
        center;
      background-size: 100% 100%;
      padding-top: 135px;
      box-sizing: border-box;
      background-color: #fff;
      div {
        height: 75px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 250%);
        padding-top: 15px;
        box-sizing: border-box;
        .product__first-line__title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          padding-left: 22px;
        }
        .product__first-line__english {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          padding-left: 22px;
          margin-top: 3px;
        }
      }
    }
    .intercom-host {
      width: 290px;
      height: 100%;
      background-color: #f2f2f2;
      margin-left: 10px;
    }
  }
  .LCD {
    width: 290px;
    height: 100%;
    background-color: #f2f2f2;
    margin-left: 10px;
  }
}
.functional-content {
  width: 100%;
  height: 320px;
  display: flex;

  div:nth-child(1) {
    height: 100%;
    width: 280px;
    background-color: #f9f9f9;
    margin-right: 32px;
    padding: 33px;
    box-sizing: border-box;
    .functional-content__details {
      font-size: 15px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 30px;
      color: #515151;
      line-height: 25px;
      strong {
        font-weight: 900;
        color: #000;
      }
    }
    .functional-content__title {
      font-size: 36px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      color: #ededed;
      margin-top: 40px;
    }
    .functional-content__english {
      font-size: 18px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      color: #ededed;
      margin-top: 10px;
    }
  }
  div:nth-child(2) {
    height: 100%;
    width: 280px;
    background-color: #fff;
    margin-right: 32px;
    box-sizing: border-box;
    p:nth-child(1) {
      width: 100%;
      height: 170px;
      background-color: #f9f9f9;
      padding: 33px;
      box-sizing: border-box;
      font-size: 15px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 30px;
      color: #515151;
      line-height: 25px;
      strong {
        font-weight: 900;
        color: #000;
      }
    }
    p:nth-child(2) {
      width: 100%;
      height: 118px;
      background-color: #f9f9f9;
      margin-top: 32px;
      padding: 33px;
      box-sizing: border-box;
      font-size: 15px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      line-height: 30px;
      color: #515151;
      line-height: 25px;
    }
  }
  div:nth-child(3) {
    height: 100%;
    width: 280px;
    background-color: #fff;
    p {
      width: 100%;
      height: 85px;
      background-color: #f9f9f9;
      margin-bottom: 32px;
      padding: 20px 30px;
      box-sizing: border-box;
      font-size: 15px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #515151;
      line-height: 25px;
      strong {
        font-weight: 900;
        color: #000;
      }
    }
  }
}
.life {
  width: 100%;
  height: 450px;
  margin-top: 20px;
}
.right__title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #e8e8e8;
  display: block;
  line-height: 45px;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 26px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
}
.title-buttom {
  width: 208px;
  height: 2px;
  background-color: #189142;
  border-radius: 2px;
  display: block;
  margin-left: 348px;
  margin-top: -2px;
}
.right__details {
  margin-top: 30px;
  font-size: 15px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  text-indent: 2em;
}
</style>
