<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-logo">
        <img src="../../assets/img/footerLogo.png" alt="" />
      </div>
      <div class="footer-middle">
        <p>网站导航</p>
        <i></i>
        <div>
          <router-link to="/home">网站首页</router-link>
          <router-link to="/center">产品中心</router-link>
          <router-link to="/case">合作案例<br /></router-link>
          <p>
            <router-link to="/product">解决方案</router-link>
            <router-link to="/affairs">招商加盟</router-link>
            <router-link to="/about">关于昊森</router-link>
          </p>
        </div>
      </div>
      <div class="footer-right">
        <p class="contact-us">联系我们</p>
        <i></i>
        <div class="phone">
          <p>
            <span>服务热线：</span>
            <span class="phone-number">028-87750979</span>
          </p>
          <p>
            <span>销售电话：</span>
            <span class="phone-number">13158578080</span>
          </p>
        </div>
      </div>
    </div>
    <div class="footer-buttom">© 2012-2023&emsp;&emsp;成都昊森电子科技有限公司&emsp;All Rights Reserved&emsp;蜀ICP备2021031683号-1&emsp;技术支持：昊森科技</div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 280px;
  background-color: #43485c;
  display: flex;
  flex-direction: column;
  .footer-main {
    width: 1200px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 230px;
      height: 67px;
    }
    .footer-logo {
      width: 400px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer-middle {
      width: 400px;
      height: 120px;
      border-left: 1px solid #868996;
      padding-left: 85px;
      p {
        font-size: 18px;
        color: #fff;
      }
      i {
        width: 35px;
        height: 3px;
        background-color: #009049;
        display: block;
        border-radius: 5px;
        margin-top: 8px;
      }
      div {
        margin-top: 18px;
        a {
          font-size: 14px;
          color: #fff;
          margin-right: 50px !important;
        }
        p {
          margin-top: 15px;
        }
      }
    }
    .footer-right {
      width: 400px;
      height: 120px;
      border-left: 1px solid #868996;
      .contact-us {
        font-size: 18px;
        color: #fff;
        margin-left: 85px;
      }
      i {
        margin-left: 85px;
        width: 35px;
        height: 3px;
        background-color: #009049;
        display: block;
        border-radius: 5px;
        margin-top: 8px;
      }
      .phone {
        margin-left: 85px;
        p {
          color: #fff;
          font-size: 14px;
          margin-top: 13px;
          display: flex;
          align-items: center;
          .phone-number {
            font-size: 22px !important;
          }
        }
      }
    }
  }
  .footer-buttom {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;
    border-top: 1px solid #868996;
  }
}
</style>
