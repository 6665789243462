<template>
  <div class="headerWarpper">
    <div class="contentWarpper content">
      <img src="../../assets/img/logo.png" />
      <div class="nav-arpper">
        <router-link to="/home">首页</router-link>
        <router-link
          to="/product"
          :class="{
            'router-link-exact-active': $route.path.indexOf('/product') != -1,
          }"
          >解决方案</router-link
        >
        <router-link to="/center">产品中心</router-link>
        <router-link to="/affairs">招商加盟</router-link>
        <router-link to="/case">合作案例</router-link>
        <router-link to="/about">关于昊森</router-link>
      </div>
      <div class="nav-phone">
        <img src="../../assets/img/navPhone.png" alt="" class="nav-phone-img" />
        <span class="nav-phone-text">028-87750979</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.headerWarpper {
  width: 100%;
  height: 80px;
  position: sticky;
  z-index: 999999;
  .content {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-arpper {
        // margin-left: 110px;
      a {
        display: inline-block;
        margin-left: 40px;
        height: 77px;
        font-weight: 400;
        line-height: 80px;
        font-size: 18px;
        color: #707070;
        text-align: center;
        &.router-link-exact-active {
          font-weight: bold;
          color: #009049;
          // border-bottom: 3px solid #009049;
        }
      }
    }
    .nav-phone {
      height: 80px;
      display: flex;
      align-items:center;
      justify-content: center;
      .nav-phone-img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      .nav-phone-text {
        height: 30px;
        font-size: 22px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        line-height: 30px;
        color: #009049;
        opacity: 1;
      }
    }
  }
}
</style>
