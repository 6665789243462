<template>
  <div class="warpper">
    <section class="section">
      <div class="contentWarpper scheme-title">
        <img src="../assets/img/caseH1.png" alt="" />
      </div>
    </section>
    <div class="contentWarpper case">
      <p class="current-position">
        您当前的位置：<router-link to="/home">首页</router-link> > 合作案例
      </p>
      <div class="case-content">
        <div>
          <img src="../assets/img/homeCase01.jpg" alt="" />
          <p>新都第二人民医院</p>
          <p>Xindu Second People's Hospital</p>
        </div>
        <div>
          <img src="../assets/img/homeCase02.jpg" alt="" />
          <p>四川省人民医院</p>
          <p>Sichuan Provincial People's Hospital</p>
        </div>
        <div>
          <img src="../assets/img/homeCase03.jpg" alt="" />
          <p>新都木兰社区卫生服务中心</p>
          <p>Xindu Mulan Community Health Service Center</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.case {
  margin-top: 30px;
  margin-bottom: 160px;
  .current-position {
    font-size: 16px;
    font-family: Microsoft YaHei UI;
    color: #666666;
    a {
      color: #009049;
    }
  }
  .case-content {
    width: 100%;
    height: 400px;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    div {
      height: 100%;
      width: 375px;
      background-color: #ebebeb;
      img {
        width: 100%;
        height: 300px;
      }
      p:nth-child(2) {
        font-size: 22px;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        margin: 20px 0 0 25px;
      }
      p:nth-child(3) {
        font-size: 12px;
        font-family: Microsoft YaHei UI;
        line-height: 30px;
        color: #555555;
        margin: 0px 0 0 25px;
      }
    }
  }
}
.warpper {
  width: 100%;
  .section {
    background: url("../assets/img/caseBanner.jpg") no-repeat center;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    height: 455px;
    padding-top: 110px;
    box-sizing: border-box;
    .scheme-title {
      img {
        width: 675px;
        height: 230px;
      }
    }
  }
}
</style>
